@import url(https://fonts.googleapis.com/css2?family=Yanone+Kaffeesatz:wght@400;500;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  background-color: rgb(70, 1, 1);
}

.jsOnly {
  display: none;
}

#pianoChasis {
  margin-top: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.piano {
  -webkit-transform: scale(200%);
          transform: scale(200%);
}

.key-button {
  border: none;
  outline: none;
  caret-color: transparent;
}

.piano-board {
  /* width: 650px;
  height: 105px; */
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: rgb(70, 1, 1);
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.white-key {
  width: 25px;
  height: 100px;
  border-radius: 0 0 5px 5px;
  background: rgb(232, 212, 196);
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  margin-left: 2px;
  opacity: 1;
}

.black-key {
  width: 15px;
  height: 70px;
  border-radius: 0 0 5px 5px;
  background: black;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  margin-left: -7px;
  margin-right: -8px;
  opacity: 1;
  z-index: 1;
}

.C:focus {
  background: rgb(255, 124, 124);
}
.Cs:focus {
  background: rgb(255, 159, 124);
}
.D:focus {
  background: rgb(245, 252, 108);
}
.Ds:focus {
  background: rgb(248, 255, 124);
}
.E:focus {
  background: rgb(205, 255, 124);
}
.F:focus {
  background: rgb(124, 255, 176);
}
.Fs:focus {
  background: rgb(124, 255, 235);
}
.G:focus {
  background: rgb(124, 205, 255);
}
.Gs:focus {
  background: rgb(124, 155, 255);
}
.A:focus {
  background: rgb(152, 124, 255);
}
.As:focus {
  background: rgb(211, 124, 255);
}
.B:focus {
  background: rgb(255, 124, 224);
}

@media (max-width: 1500px) {
  .piano {
    -webkit-transform: scale(150%);
            transform: scale(150%);
    top: 40%;
    left: 25%;
  }
  .piano-key {
    margin-left: 4px;
  }

  .black-key {
    margin-left: -7px;
    margin-right: -8px;
  }
}

@media (max-width: 800px) {
  .piano {
    -webkit-transform: scale(125%);
            transform: scale(125%);
    top: 40%;
    left: 11%;
  }
}

#octave-down:focus {
  background-color: #01b7a8;
  outline: none;
}
#octave-up:focus {
  background-color: #01b7a8;
  outline: none;
}

.octave-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: black;
  position: relative;
  bottom: 10px;
  width: 100px;
  height: 15px;
  border-radius: 5px;
}

.octave-font {
  font-family: "Yanone Kaffeesatz";
  font-weight: 600;
  font-size: 11px;
  color: white;
}

.octave-button {
  height: 15px;
  width: 50px;
  background-color: inherit;
  border-style: unset;
}

.octave-button:active {
  background-color: #01b7a8;
}

.octave-button-left {
  border-radius: 5px 0px 0px 5px;
}
.octave-button-right {
  border-radius: 0px 5px 5px 0px;
}

.octave-span {
  margin-left: 5px;
  margin-right: 5px;
}

